<template>
  <div class="flex w-1/2 flex-col gap-2 text-start">
    <span class="text-subhead-2"
      >{{ union === 'and' ? 'Leads that match ALL of these conditions' : 'and match ANY of these conditions' }}
    </span>
    <div class="flex max-h-[336px] flex-col gap-4 overflow-auto rounded-lg bg-black-03 p-3">
      <UiLoader v-if="loading" />
      <template v-else>
        <TransitionGroup name="fade">
          <div v-for="(rule, index) in modelValue" :key="index" class="flex w-full flex-row items-center gap-3">
            <div class="flex w-full flex-row items-center gap-2">
              <div class="flex flex-1">
                <UiInputSelect
                  :id="`rule_name${index}`"
                  v-model="rule.rule_name"
                  :name="`rule_name${index}`"
                  :items="parameterNameItems"
                  compact
                  :error="useGetFieldErrorsByIndex(v, union === 'and' ? 'rules' : 'rulesOr', 'rule_name', index)"
                  @update:model-value="rule.rule_value = ''"
                ></UiInputSelect>
              </div>
              <div class="flex flex-1">
                <UiInputSelect
                  v-model="rule.rule_value"
                  :items="getParameterValues(rule.rule_name)"
                  :avatar="getIsRuleForAgents(rule)"
                  :group="getIsRuleForAgents(rule)"
                  name="name"
                  add-new
                  compact
                  placeholder="Select option"
                  :error="useGetFieldErrorsByIndex(v, union === 'and' ? 'rules' : 'rulesOr', 'rule_value', index)"
                  @update:model-value="addCustomValue(rule, $event)"
                />
              </div>
            </div>
            <div class="flex w-14 min-w-12 flex-row items-center gap-2">
              <span class="text-table-header text-black-40">{{ union }}</span>
              <div class="w-4">
                <UiIcon
                  name="big-close-circle-filled"
                  size="xxs"
                  class="!cursor-pointer text-primary-100"
                  @click="removeCondition(index)"
                />
              </div>
            </div>
          </div>
        </TransitionGroup>
        <UiButtonGhost id="add-condition" @click="addCondition">
          <UiIcon name="big-add-circle" />
          Add condition
        </UiButtonGhost>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import { PARAMETERS_NAMES_MAP } from '~/constants'
import type { InputItem, MappingRule, Country, Language } from '~/types'

const modelValue = defineModel<MappingRule[]>()

type Props = {
  v: Validation
  union?: 'and' | 'or'
}
const props = withDefaults(defineProps<Props>(), {
  union: 'and',
})

const loading = ref(true)
const parameterNameItems = ref<InputItem[]>([])
const allParameters = ref([])
const customValues = ref({
  agentsItems: [] as InputItem[],
  languagesItems: [] as InputItem[],
  countryItems: [] as InputItem[],
})

onNuxtReady(async () => {
  await Promise.all([getAgents(), getLanguages(), getCountries(), getParameters()])
  loading.value = false
})

const getParameters = async () => {
  const response = await useGetRotationsParameters()
  const parameters = Object.entries(response.data).map((i) => ({
    value: i[0],
    text: PARAMETERS_NAMES_MAP.get(i[0])!,
  }))

  const sortOrder = Array.from(PARAMETERS_NAMES_MAP.keys())

  parameterNameItems.value = parameters.sort((a, b) => {
    return sortOrder.indexOf(a.value) - sortOrder.indexOf(b.value)
  })

  allParameters.value = toRaw(response.data)
}

const AGENT_ID = 'agent_id'

const getIsRuleForAgents = (rule: MappingRule) => rule.rule_name === AGENT_ID

const getParameterValues = (ruleName: keyof typeof allParameters.value) => {
  const parametersWithCustomValues = {
    language_iso: 'languagesItems',
    country_iso: 'countryItems',
    [AGENT_ID]: 'agentsItems',
  }

  if (!ruleName || !allParameters.value[ruleName]) return []
  if (!parametersWithCustomValues[ruleName] && allParameters.value[ruleName]) {
    return allParameters.value[ruleName].filter((i) => i).map((i) => ({ value: i, text: i }))
  }

  return customValues.value[parametersWithCustomValues[ruleName]]
}

const addCustomValue = (rule: MappingRule, value: string) => {
  if (!allParameters.value[rule.rule_name].includes(value)) allParameters.value[rule.rule_name].push(value)
}

const getAgents = async () => {
  customValues.value.agentsItems = await useGetUsersItems(true)
}

const getLanguages = async () => {
  const languagesList = await useLanguages()
  customValues.value.languagesItems = languagesList.map((l: Language) => ({
    text: l.name,
    value: l.code.toUpperCase(),
  }))
}

const getCountries = async () => {
  const countries = await useCountries()
  customValues.value.countryItems = countries.map((c: Country) => ({ text: c.name, value: c.iso2 }))
}

const addCondition = () => {
  // insert the first item from the parameterNameItems that is not in the array yet
  modelValue.value!.push({
    rule_name: parameterNameItems.value.find(
      (a: InputItem) => !modelValue.value!.some((m: MappingRule) => m.rule_name === a.value)
    )!.value,
    rule_operator: '=',
    rule_value: '',
    union_operator: props.union,
  })
}

const removeCondition = (index: number) => {
  modelValue.value!.splice(index, 1)
}
</script>

<style scoped></style>
